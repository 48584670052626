import { Component, inject } from '@angular/core';
import { ThemingService } from '../../theming.service';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-invalid-link-view',
  standalone: true,
  imports: [TranslocoPipe],
  templateUrl: './invalid-link-view.component.html',
  styleUrl: './invalid-link-view.component.scss',
})
export class InvalidLinkViewComponent {
  themingService = inject(ThemingService);
}
