import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

fetch(`${environment.apiUrl}`)
  .then(
    (el) => el,
    () => {
      environment.apiUrl = environment.apiUrlFallback;
      return fetch(`${environment.apiUrl}`);
    }
  )
  .catch((err) => console.error(err))
  .finally(() => {
    bootstrapApplication(AppComponent, appConfig)
      .then(() => console.log('Bootstrap success'))
      .catch((err) => console.error(err));
  });
