import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  inject,
  Injectable, signal,
  WritableSignal
} from '@angular/core';
import { environment } from '../environments/environment';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  private httpClient = inject(HttpClient);
  design: WritableSignal<Design | null> = signal(null);
  router: Router = inject(Router);

  constructor() {
    this.setDefaultDesign();
  }

  loadDesign(jobId: number) {
    if (jobId === undefined) {
      this.setDefaultDesign();
    }

    this.httpClient
      .get<Design>(`${environment.apiUrl}/leads/jobs/${jobId}/design`)
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 404) {
              this.setDefaultDesign();
              this.router.navigate(['/']);
            }
          }
          throw error;
        })
      )
      .subscribe((design) => this.set(design));
  }

  private setDefaultDesign() {
    this.set({
      companyName: 'Catch',
      accent: '#374678',
      background: '#6E8BEF',
      buttonFont: '#FFFFFF',
      logoURL: 'assets/img/catch_logo.png',
      iconURL: 'assets/img/catch_icon.png',
    });
  }

  private set(design: Design) {
    this.design.set(design);

    document.documentElement.style.setProperty('--accent-1', design.accent);
    document.documentElement.style.setProperty('--accent-2', design.background);
    document.documentElement.style.setProperty(
      '--font-color',
      design.buttonFont
    );

    const favIcon = document.querySelector('link[rel="icon"]') ?? null;
    if (favIcon !== null) {
      favIcon.setAttribute('href', design.iconURL);
    }
  }
}

interface Design {
  companyName: string;
  accent: string;
  background: string;
  buttonFont: string;
  logoURL: string;
  iconURL: string;
}
