export const environment = {
  version: '0-0-6',
  buildDate: '202409190953',
  environment: 'production',
  release: '85edcb33ff593cd4915546aaf3cd6791a11d85c8',
  production: true,
  debug: false,
  baseUrl: 'https://leads.catch-talents.de',
  apiUrl: 'https://api.catch-talents.de',
  apiUrlFallback: 'https://api.catch-talents.de',
  companyBaseUrl: 'https://company.catch-talents.de',
  jobboardBaseUrl: 'https://jobboard.catch-talents.de',
};
