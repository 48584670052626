import { Routes } from '@angular/router';
import { MainViewComponent } from './components/main-view/main-view.component';
import { InvalidLinkViewComponent } from './components/invalid-link-view/invalid-link-view.component';
import { SuccessViewComponent } from './components/success-view/success-view.component';

export const routes: Routes = [
  {
    path: 'form/:jobId',
    pathMatch: 'full',
    component: MainViewComponent,
  },
  {
    path: 'success',
    pathMatch: 'full',
    component: SuccessViewComponent,
  },
  {
    path: 'invalid-link',
    pathMatch: 'full',
    component: InvalidLinkViewComponent,
  },
  {
    path: '**',
    redirectTo: 'invalid-link',
  }
];
