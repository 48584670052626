import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { ThemingService } from './theming.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  themingService = inject(ThemingService);
  translocoService = inject(TranslocoService);

  ngOnInit(): void {
    this.translocoService.setActiveLang(
      navigator.language.split('-')[0] ?? 'en'
    );
  }
}
